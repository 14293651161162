<template>
  <div v-loading="loaders.response">
    <div class="ml-4 pl-3">
      <back-button/>
    </div>
    <div class="container-fluid d-flex justify-content-between mt-4 mb-4 row">
      <span class="ml-3 trophy-heading mt-2">
        View Bonus <span class="trophy-sub-heading">(Cards and Trophies)</span>
      </span>
      <router-link class="router-link pr-2" :to="{name: 'EditTrophyCard' , params: { id: trophyCardId } }">
        <base-button type="secondary" class="pt-2 pb-2 font-weight-normal custom-button">
          Edit
        </base-button>
      </router-link>
    </div>
    <div class="mt-1 row px-3 mx-0">
      <div class="col-6">
        <card class="equal-height-columns" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div slot="header" class="d-flex">
            <div class="row justify-content-between w-100 ml-2 mr-0">
              <div class="d-inline-block">
                <h1 class="ad-image-heading ellipsis-overflow-text">AD IMAGE</h1>
              </div>
              <div class="d-inline-block">
                <p class="card-status px-3 py-1 mb-0"
                   :class="trophyCard.status === 0 ? 'inactive' : 'active'">
                  {{ getStatus(trophyCard.status) }}
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-5 p-0 mr-3">
              <h5 class="mb-0 pb-3 image-label">Front</h5>
              <img class="" :src="trophyCard.ad_front_image" alt=""/>
            </div>
            <div class="col-5 p-0 ml-3" v-if="trophyCard.ad_back_image">
              <h5 class="mb-0 pb-3 image-label">Back</h5>
              <img class="" :src="trophyCard.ad_back_image" alt=""/>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6 content-section-card-width">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div slot="header" class="d-flex">
            <h2 class="basic-info-heading">BASIC INFO</h2>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Title</div>
            <div class="col-6">{{ trophyCard.title }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">User Type</div>
            <div class="col-6">{{ trophyCard.user_tier | userTier }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Based On</div>
            <div class="col-6">{{ trophyCard.rule_type | ruleType }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">{{ getDateHeading(trophyCard.rule_type) }}</div>
            <div class="col-6">{{ getDateText(trophyCard) }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Link</div>
            <div class="col-6 pr-4"><a target="_blank" :href="trophyCard.link">{{ trophyCard.link }}</a></div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Notification</div>
            <div class="col-6 pr-4">{{ trophyCard.notification_content }}</div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import {constants} from "@/util/constants";

export default {
  name: "ShowTrophyCard",
  components: {BackButton},
  data() {
    return {
      trophyCardId: this.$route.params.id,
      loaders: {
        response: false
      },
      trophyCard: {...this.$store.getters['CardsAndTrophiesModule/getCardTrophy']}
    }
  },
  filters: {
    userTier(user_tier) {
      let userTiers = ''
      if (user_tier) {
        user_tier.split(',').forEach(function(tier, index, array) {
          userTiers += constants.cardsTrophies.userTierTypes[tier]
          if (index != array.length - 1) userTiers += ', '
        })
      }
      return userTiers
    },

    ruleType(rule_type) {
      return constants.cardsTrophies.ruleTypes[rule_type] ?? ''
    }
  },
  mounted() {
    this.getTrophyCard()
  },
  methods: {
    /**
     * Get trophy card
     */
    getTrophyCard() {
      let vm = this
      vm.loaders.response = true
      const payload = {
        id: vm.trophyCardId
      }
      vm.$store.dispatch('CardsAndTrophiesModule/_getRequestedTrophyCard', payload)
        .then(response => {
          vm.trophyCard = response.data.data
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Trophy Card',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.response = false
        })
    },

    /**
     * Get days text
     * @param trophyCard
     * @returns {string}
     */
    getDateText(trophyCard) {
      let dateText = ''
      if (trophyCard.rule_type) {
        if (trophyCard.rule_type === 'food_log' || trophyCard.rule_type === 'app_usage')
        {
          dateText = constants.cardsTrophies.daysConditions[trophyCard.days_condition] + ', '
          dateText += trophyCard.days + ' ' + (trophyCard.days > 1 ? ' Days' : ' Day')
        } else if (trophyCard.rule_type === 'specific_date') {
          dateText = trophyCard.date;
        } else {
          dateText = constants.cardsTrophies.daysConditions[trophyCard.days_condition] + ', '
          dateText += trophyCard.surgery_days + ' ' + constants.cardsTrophies.surgeryDayTypes[trophyCard.surgery_day_type]
          dateText += trophyCard.surgery_days > 1 ? 's' : ''
        }
      }

      return dateText
    },

    /**
     * Get date heading
     * @param ruleType
     * @returns {string}
     */
    getDateHeading(ruleType) {
      if (ruleType === 'food_log') {
        return 'Food Log'
      } else if (ruleType === 'app_usage') {
        return 'App Usage'
      } else if (ruleType === 'specific_date') {
        return 'Specific Date'
      } else {
        return 'Surgery Date'
      }
    },

    /**
     * Get trophy card status
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },
  },
}
</script>

<style scoped>
.ad-image-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.trophy-heading {
  text-align: left;
  font: normal normal 600 25px/24px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
}

.trophy-sub-heading {
  text-align: left;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
}

.active {
  background: #94B527 0 0 no-repeat padding-box;
}

.inactive {
  background: #BD3D55 0 0 no-repeat padding-box;
}

.basic-info-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.card-status {
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #FFFFFF;
}
</style>
